import * as React from "react";


// Components
import { useMediaQuery, useTheme, AppBar, Box, Button, CardMedia, Grow, Link, Toolbar, Typography, Stack, Slide } from "@mui/material";
import { alpha } from '@mui/material/styles';


const logo_2 = require("../assets/logo_2.png");

// Services

// Plugins

// Constants
const alphaBlack = alpha('#121212', 0);
const alphaBlack2 = alpha('#121212', .55);

const CALL_NUM = "6292663848";
const CALL_NUM_DISPLAY = "(629) 266-3848";

export default function LandingHeader() {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <AppBar
        component="header"
        position="static"
        sx={{
          width: "100%",
          background: alphaBlack,
          boxShadow: "none",
        }}>
          {bp ? <Toolbar disableGutters sx={{justifyContent: "space-evenly", background: alphaBlack2, mt: 3 }}>
              <Box sx={{ maxHeight: "275px", maxWidth: "275px", width: "auto", height: "auto", }}>
                <CardMedia component="img"  image={logo_2} alt="logo"  />
              </Box>
              <Stack>
                <Typography variant="h2" color="white" textAlign="center" sx={{ textShadow: '-2px 2px 0 #fff' }}>No Cost, No Stress Speedy Lawyers</Typography>
                <Typography variant="h2" fontStyle="italic" textAlign="center" color="white" sx={{ textShadow: '-2px 2px 0 #fff' }}>Matches You with the Best!</Typography>
              </Stack>
              <Stack alignItems="center" spacing={4}>
                <Grow in timeout={700}>
                <Button
                  size="large"
                  variant="contained"
                  sx={{
                    width: "25rem",
                    height: "6.5rem",
                    borderRadius: '5rem',
                    bgcolor: "yellow",
                    boxShadow: "4px 4px #e57373",
                  }}
                  onClick={() => {
                    document.location.href = `tel:${CALL_NUM}`}
                  }
                >
                  <Stack >
                    <Typography fontSize={"2rem"} color="black" sx={{ textShadow: '-1px 1px 0 black' }}>Call Us Now!</Typography>
                    <Typography fontSize={"1.5rem"} color="black" sx={{ textShadow: '-1px 1px 0 black' }}>Click Here!</Typography>
                  </Stack>
                </Button>
                </Grow>
                <Box display="flex" flexDirection="row" alignItems="center" mt={2}><Box><Link href={`tel:${CALL_NUM}`} sx={{ color: "white", textShadow: '-1px 1px 0 #fff', textUnderlineOffset: "12px" }}><Typography variant="h3">{CALL_NUM_DISPLAY}</Typography></Link></Box></Box>
              </Stack>
          </Toolbar> : 
          <Stack direction="column" alignItems="center" mt={5} bgcolor={alphaBlack2}>
            <Slide in direction="right">
              <Box display="flex" flexDirection="row">
                <Typography variant="h1" color="white" fontStyle="italic"  sx={{ textShadow: '-1px 1px 0 #fff' }}>Speedy Lawyers</Typography>
              </Box>
            </Slide>
            <Stack ml={1} mt={4} direction="column" alignItems="center" spacing={1}>
              <Typography variant="h3" color="white" textAlign="center" sx={{ textShadow: '-1px 1px 0 #fff' }}>No Cost, No Stress Speedy Lawyers</Typography>
              <Typography variant="h3" fontStyle="italic" color="white" sx={{ textShadow: '-1px 1px 0 #fff' }}>Matches You with the Best!</Typography>
            </Stack>
            <Grow in timeout={700}>
              <Box display="flex" flexDirection="row" alignItems="center" mt={4}><Box><Link href={`tel:${CALL_NUM}`} sx={{ color: "white", textShadow: '-1px 1px 0 #fff', textDecoration: "none" }}><Typography variant="h3" sx={{ border: "2px solid white", borderRadius: "28px", p: "12px" }}>{CALL_NUM_DISPLAY}</Typography></Link></Box></Box>
            </Grow>
          </Stack>
          }
      </AppBar>
    </>
  );
}
